import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import { pink } from '@material-ui/core/colors';
// import defaultTheme from './default_theme'; // need to use deep merge

// https://material-ui.com/customization/theming/#customizing-all-instances-of-a-component-type
// https://material-ui.com/customization/color/

const palette = createPalette({
    type: 'light',
    topBarColor: '#505759',
    primary: {
        light: '#FFFFFF',
        main: '#E3E2DD',
        dark: '#CDCDCA',
        contrastText: '#505759',
        negative: '#EE4248',
        manatee: '#959595',
        dim: '#707070',
        grey: '#464646'
    },
    secondary: {
        main: '#DC4405',
        contrastText: '#FFFFFF',
        dark: '#AA4C37',
        light: '#E9D3CC',
        iconHoverColor: '#CCCCCC',
        actionHoverColor: '#f39305'
    },
    userLayer: {
        main: '#e58e0d',
        contrastText: '#FFFFFF'
    },
    error: {
        ...pink
    },
    abmiColor: '#859254'
});

const typography = {
    button: {
        fontSize: 11
    },
    h1: {

    },
    h2: {

    },
    h3: {

    },
    h4: {

    },
    h5: {

    },
    h6: {

    },
    subtitle1: {

    },
    body1: {
        fontSize: 15
    },
    body2: {
        fontSize: 13
    }
};

const actionBtn = {
    textTransform: 'uppercase',
    fontSize: 14
};

const topBarHeight = 100;
const commonBorder = '1px solid ' + palette.primary.dark;

const theme = createMuiTheme({
    // material-ui
    palette: palette, // { ...defaultTheme.palette, palette },
    typography: typography,
    topBar: {
        height: topBarHeight,
        iconHoverColor: '#DC4405',
        color: '#DC4405'
    },
    sidePanel: {
        width: 400,
        marginTop: topBarHeight,
        height: `calc(100vh - ${topBarHeight}px)`
    },
    layerCard: {
        backgroundColor: 'transparent',
        borderRadius: 0,
        borderBottom: commonBorder,
        boxShadow: 'none'
    },
    actionBtn: actionBtn,
    cancelBtn: {
        ...actionBtn,
        color: palette.primary.dim
    },
    removeBtn: {
        ...actionBtn,
        color: palette.error.main
    },
    commonSpacing: {
        paddingLeft: 16,
        paddingRight: 12
    },
    commonPanel: {
        borderBottom: commonBorder,
        minHeight: 40
    },
    commonAlignment: {
        display: 'flex',
        alignItems: 'center'
    },
    menuIcon: {
        marginRight: 7,
        marginLeft: -3
    },
    overrides: {
        MuiCardHeader: {
            root: {
                padding: 0
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                borderRadius: 2
            },
            text: {
                '&:focus': {
                    color: palette.secondary.main
                },
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            textSecondary: {
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            contained: {
                "textTransform": 'uppercase',
                '&:disabled': {
                    color: palette.primary.light
                }
            },
            outlined: {
                textTransform: 'uppercase'
            }
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            colorSecondary: {
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: palette.secondary.main
                }
            }
        },
        MuiInputLabel: {
            root: {
                '&$focused': {
                    color: palette.secondary.main
                },
                '&$error': {
                    color: palette.error.main
                }
            },
            shrink: {
                /*increasing the scaling factor here allows for slightly bigger font than the default one*/
                transform: 'translate(0, 1.5px) scale(0.8)'
            }
        },
        MuiLink: {
            root: {
                color: palette.secondary.main
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                "padding": '0 12px',
                "minHeight": '44px',
                '&$expanded': {
                    minHeight: '44px'
                },
                '& button': {
                    padding: '8px 12px'
                },
                '& p': {
                    padding: '4px 0'
                }
            },
            content: {
                "margin": 0,
                "alignItems": 'center',
                '&$expanded': {
                    margin: 0
                }
            }
        },
        MuiExpansionPanel: {
            root: {
                '&$expanded': {
                    margin: 0
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 13
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 14,
                minHeight: '32px!important',
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    }
});

export default theme;

import React from 'react';
import propTypes from 'prop-types';

// routing
import {Switch} from 'react-router-dom';
import asyncComponent from './components/AsyncComponent';
import AppliedRoute from './components/AppliedRoute';

// pages
const AsyncHome = asyncComponent(() => import('./routes/Home'));

const Routes = (props) => {
    const {childProps} = props;
    return (
        <Switch>
            <AppliedRoute path='/' exact component={AsyncHome} childProps={childProps}/>
            <AppliedRoute path='/:id' exact component={AsyncHome} childProps={childProps}/>
        </Switch>
    );
};

Routes.propTypes = {
    childProps: propTypes.object.isRequired
};

export default Routes;

import React, { Component } from 'react';
import propTypes from 'prop-types';

// theme
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import themeList from './theme/';
import '../node_modules/dragula/dist/dragula.css';
import './css/roboto.css';
import './css/abmi.css';

// i18n
import { IntlProvider } from 'react-intl';
import initLocale,  {getLocaleMessages}  from 'react-intl-locale';

// routing
import { HashRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { OnUpdate } from 'rrc';

// LocalStorage service for storing user preferences
import Storage from './Services/LocalStorage/LocalStorage';

// Set locale
const defaultLocale = 'en-CA';
let locale = defaultLocale + '';

// initLocale fails if browser restricts access to local storage, so this is to prevent app from crashing in those cases
try {
    locale = initLocale(defaultLocale, []);
} catch (e) {}

const styles = (theme) => {
    return {
        // Be sure to include defaults listed here if planning to override
        // https://github.com/callemall/material-ui/blob/v1-beta/docs/src/modules/components/AppFrame.js
        '@global': {
            'html': {
                'background': theme.palette.background.default,
                'WebkitFontSmoothing': 'antialiased', // Antialiasing.
                'MozOsxFontSmoothing': 'grayscale', // Antialiasing.
                'boxSizing': 'border-box',
                '@media print': {
                    background: theme.palette.common.white
                },
                // override
                //'fontSize': '62.5%',
                'scrollBehavior': 'smooth',
                'fontFamily': theme.typography.fontFamily,
                textTransform: 'none'
            },
            'body': {
                margin: 0,
                // override
                padding: 0,
                textTransform: 'none'
            },
            // allows for sticky footer
            '#root': {
                // minHeight: '100vh',
                position: 'relative ',
                textTransform: 'none'
            }
        },
        "root": {
            paddingBottom: '15.0rem',   // max height of footer w/ padding
            textTransform: 'none'
        }
    }
};

// check if user has already seen prompt in the past 24 hrs
function showPromptOnExit () {
    let show = null; // flag - return for if prompt should be shown or not - false still triggers the prompt, so have to set it as null
    let settings = Storage.getItem('preferences');
    let limit = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

    if (settings) {
        let timestamp = new Date(settings.exitPrompt.timestamp)
        let now = new Date()
        let duration = now - timestamp;
        // if it has been more than 24 hours, then show prompt
        if (duration >= limit) {
            show = true;
        }
    } else {
        show = true;
    }

    // if we're showing prompt, update timestamp or set it for the first time
    if (show) {
        settings = {
            exitPrompt: {
                timestamp: new Date()
            }
        }
        Storage.setItem('preferences', settings)
    }
    return show;
}

class App extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            locale: locale,
            messages: {},
            portalName: null
        };
    }

    componentWillMount() {
        this.initialize()

        window.onbeforeunload = function () {
            // prompt will only show if there is user interaction with the app
            return showPromptOnExit();
        }
    }
    initialize () {
        const publicUrl = process.env.PUBLIC_URL || '';
        let fileList = [ publicUrl + '/static/lang/{locale}.global.json'];
        const portalName = process.env.REACT_APP_PORTAL_NAME;

        fileList.push(publicUrl + `/static/lang/${(portalName || 'default').toLowerCase()}.{locale}.global.json`);
        this.setState({portalName:  portalName});

        getLocaleMessages(this.state.locale, fileList )
            .then((messages) => {
                this.setState({messages});
            });
    }
    render () {
        const { locale, messages, isAuthenticated, portalName } = this.state;
        const { classes } = this.props;

        const childProps = { isAuthenticated, locale, portalName };
        const theme = themeList[portalName || 'default'];
        if (!Object.keys(messages).length) return (<div></div>);

        return (
            <MuiThemeProvider theme={theme}>
                <IntlProvider
                    locale={locale}
                    messages={messages}
                >
                    <Router className={classes.root}>
                        <div>
                            <OnUpdate immediate
                                call={(location) => {
                                    //analyticsTool.log(location.pathname)
                                }} />
                            <Routes childProps={childProps}/>
                        </div>
                    </Router>
                </IntlProvider>
            </MuiThemeProvider>
        );
    }
}

App.propTypes = {
    classes: propTypes.object.isRequired
};

export default withStyles(styles)(App);

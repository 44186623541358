import defaultTheme from './default_theme';
import esaTheme from './esa_theme';
import riccTheme from './ricc_theme';
const themeList = {
    esa: esaTheme,
    ricc: riccTheme,
    default: defaultTheme
}
/* load mulitple theme first, as the theme files are small,
then based on environment variable to choose the one used.

tried but failed:
to use dynamic import theme files, and however due to asynchronzied calls,
export can't be grananteed to wait for the import to be done. */
export default themeList;

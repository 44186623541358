class LocalStore {
  constructor () {
    this.supported = false;
    this.store = {}; // local memory storage as workaround
    this.type = 'memory'; // storage type - are we saving to user's local storage or in memory
    this.service = null;

    try {
      // test that I have the ability to store items, browser may reject storing/getting if in Incognito/Private mode
      window.localStorage.setItem('test', true);
      window.localStorage.removeItem('test');
      this.service = window.localStorage;
      this.supported = true;
      this.type = 'localstorage';
    } catch (e) {
      this.service = {
        setItem: (key ,value) => {
          this.store[key] = value;
        },
        getItem: (key) => {
          return this.store[key];
        },
        removeItem (key) {
          delete this.store[key];
        },
        clear () {
          this.store = {};
          return true;
        }
      }
    }
  }
  storageType () {
    return this.type + '';
  }
  setItem (key, value) {
    this.service.setItem(key, JSON.stringify(value))
  }
  getItem (key) {
    // if we cant parse it, its damaged
    try {
      let value = this.service.getItem(key);
      return value ? JSON.parse(value) : value
    } catch (e) {
      return null
    }
  }
  removeItem (key) {
    this.service.removeItem(key)
  }
  clear () {
    this.service.clear();
  }
}

export default LocalStore